<template>
    <!--사진형 갤러리 게시판-->
    <section class="mainsec">
        <div class="inner">
            <h2 class="title">사진형 갤러리 게시판</h2>
            <ul class="gallery">
                <li><a href="#none">
                    <div class="imgW"><img src="@/assets/images/common/sampleH.svg" alt=""></div>
                    <div class="txtW">
                        <p class="tit">[시설] 둥지나래 어린이도서관</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                </a></li>
                <li><a href="#none">
                    <div class="imgW"><img src="@/assets/images/common/sampleH.svg" alt=""></div>
                    <div class="txtW">
                        <p class="tit">[시설] 시청각실</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                </a></li>
                <li><a href="#none">
                    <div class="imgW"><img src="@/assets/images/common/sampleH.svg" alt=""></div>
                    <div class="txtW">
                        <p class="tit">[대관] 늘봄이음터 다목적홀</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                </a></li>
                <li><a href="#none">
                    <div class="imgW"><img src="@/assets/images/common/sampleH.svg" alt=""></div>
                    <div class="txtW">
                        <p class="tit">[공공시설] 별점도서관 문화교육시설</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                </a></li>
            </ul>
            <a href="#none" class="more btn default"><i class="micon">add</i>더보기</a>
        </div>
    </section>
    <!--//사진형 갤러리 게시판-->
</template>