<template>
    <div id="snb" class="on" v-show="showSnb" :style="{ overflow: snbOverflow }">
        <div class="inner">
            <ul class="dep">
                <li class="goHome"><router-link to="/" title="메인으로"><i class="micon">home</i></router-link></li>
                <!-- 1단계 -->
                <li class="web">
                    <a href="#none" class="depth1" @click.prevent="snbToggle">{{pageInfo.topMenuNm}}<i class="micon">keyboard_arrow_down</i></a>
                    <ul class="depList">
                        <li v-for="(item,seq) in menuList" :key="seq"><router-link :to="{path: item.menuUrl}">{{item.menuNm}}</router-link></li>
                    </ul>
                </li>
                <!-- 2단계 -->
                <li  v-if="pageInfo.upperMenuNm != null">
                    <a href="#none" class="depth2" @click.prevent="snbToggle">{{pageInfo.upperMenuNm}}<i class="micon">keyboard_arrow_down</i></a>
                    <ul class="depList">
                        <li v-for="(item,seq) in subMenuList" :key="seq"><router-link :to="{path: item.menuUrl}">{{item.menuNm}}</router-link></li>
                    </ul>
                </li>
                <!-- 3단계 -->                
                <li>
                    <a href="#none"  class="depth3" @click.prevent="snbToggle">{{pageInfo.menuNm}}<i class="micon">keyboard_arrow_down</i></a>
                    <ul class="depList">
                        <li v-for="(item,seq) in lastMenuList" :key="seq"><router-link :to="{path: item.menuUrl}">{{item.menuNm}}</router-link></li>
                    </ul>
                </li>
            </ul>
            <ul class="util">
                <li class="share"><button title="공유"><i class="micon">share</i></button></li>
                <li class="print"><button title="프린트"><i class="micon">local_printshop</i></button></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';

    export default {    
        data: () =>({            
            lastMenuView:false,
            lastScroll: 0,
            showSnb: true,
            snbOverflow: 'visible',
        }),
        beforeUnmount() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods:{
            handleScroll() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > this.lastScroll) {
                // down
                this.showSnb = false;
            } else {
                // up
                this.showSnb = true;
                this.snbOverflow = 'visible';
            }
            this.lastScroll = scrollTop;
            },
            snbToggle:function(e){
                const target = $(e.target);       
                if (!$(target).hasClass('on')) {
                    $(target).addClass('on');
                    $(target).parent('li').find(".depList").stop(true).slideDown();
                } else {
                    $(target).removeClass('on');
                    $(target).parent('li').find(".depList").stop(true).slideUp();
                }
            }
        },mounted:function(){         
            //const menuInfo = this.$store.state.menuInfo;
            //this.menuList = menuInfo;    
            window.addEventListener('scroll', this.handleScroll);          
            
        },computed:{
            pageInfo(){
                var pageInfo = this.$store.state.pageInfo;                
                return pageInfo;
            },
            menuList(){
                var menuList = this.$store.state.menuInfo;                
                return menuList;
            },
            subMenuList(){
                var menuList = this.$store.state.menuInfo;
                var pageInfo = this.$store.state.pageInfo;                
                const topMenuId   = pageInfo.topMenuId;
                let subList = null;
                for(var i = 0;i < menuList.length;i++){                        
                    if(topMenuId == menuList[i].menuId){                            
                        subList = menuList[i].subMenu;                            
                    }
                }
                return subList;
            },
            lastMenuList(){
                var menuList = this.$store.state.menuInfo;                
                var pageInfo = this.$store.state.pageInfo;
                const upperMenuId = pageInfo.upperMenuId;
                const topMenuId   = pageInfo.topMenuId;
                let subList = null;
                if(upperMenuId == null){
                    // 없으면 2단계                    
                    for(var i = 0;i < menuList.length;i++){                        
                        if(topMenuId == menuList[i].menuId){                            
                            subList = menuList[i].subMenu;                            
                        }
                    }
                }else{
                    // 있으면 3단계
                    for(var j = 0;j < menuList.length;j++){                        
                        if(topMenuId == menuList[j].menuId){              
                            let tmpList = menuList[j].subMenu;
                            for(var k = 0;k < tmpList.length;k++){
                                if(upperMenuId == tmpList[k].menuId){              
                                    subList = tmpList[k].subMenu;                            
                                }
                            }
                            
                        }
                    }                    
                }                                
                return subList;
            }
        }
    }
</script>