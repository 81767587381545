import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test/TestView.vue')
  },
  {
    path: '/contents/:contentId',
    name: 'contents',
    component: () => import('../views/contents/ContentsView.vue')
  },
  {
    path: '/boardList/:bbsId',
    name: 'boardList',
    component: () => import('../views/board/boardList.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test/TestView.vue')
  },
  {
    path: '/pboardList',
    name: 'pboardList',
    component: () => import('../views/pub/BoardList.vue')
  },
  {
    path: '/noticeList',
    name: 'noticeList',
    component: () => import('../views/pub/NoticeList.vue')
  },
  {
    path: '/noticeDet',
    name: 'noticeDet',
    component: () => import('../views/pub/NoticeDet.vue')
  },
  {
    path: '/boardFaq',
    name: 'boardFaq',
    component: () => import('../views/pub/BoardFaq.vue')
  },
  {
    path: '/boardQna',
    name: 'boardQna',
    component: () => import('../views/pub/BoardQna.vue')
  },
  {
    path: '/boardQnaDet',
    name: 'boardQnaDet',
    component: () => import('../views/pub/BoardQnaDet.vue')
  },
  {
    path: '/boardQnaWrt',
    name: 'boardQnaWrt',
    component: () => import('../views/pub/BoardQnaWrt.vue')
  },
  {
    path: '/boardRefer',
    name: 'boardRefer',
    component: () => import('../views/pub/BoardRefer.vue')
  },
  {
    path: '/boardBlog',
    name: 'boardBlog',
    component: () => import('../views/pub/BoardBlog.vue')
  },
  {
    path: '/boardBanner',
    name: 'boardBanner',
    component: () => import('../views/pub/BoardBanner.vue')
  },
  {
    path: '/boardGallery',
    name: 'boardGallery',
    component: () => import('../views/pub/BoardGallery.vue')
  },
  {
    path: '/eventList',
    name: 'eventList',
    component: () => import('../views/pub/EventList.vue')
  },
  {
    path: '/surveyList',
    name: 'surveyList',
    component: () => import('../views/pub/SurveyList.vue')
  },
  {
    path: '/surveyAgr',
    name: 'surveyAgr',
    component: () => import('../views/pub/SurveyAgree.vue')
  },
  {
    path: '/surveyWrt',
    name: 'surveyWrt',
    component: () => import('../views/pub/SurveyWrt.vue')
  },
  {
    path: '/policyInfo',
    name: 'policyInfo',
    component: () => import('../views/pub/PolicyInfo.vue')
  },
  {
    path: '/usrLogin',
    name: 'usrLogin',
    component: () => import('../views/pub/UsrLogin.vue')
  },
  {
    path: '/usrJoin01',
    name: 'usrJoin01',
    component: () => import('../views/pub/UsrJoin01.vue')
  },
  {
    path: '/usrJoin02',
    name: 'usrJoin02',
    component: () => import('../views/pub/UsrJoin02.vue')
  },
  {
    path: '/usrJoin03',
    name: 'usrJoin03',
    component: () => import('../views/pub/UsrJoin03.vue')
  },
  {
    path: '/usrJoin04',
    name: 'usrJoin04',
    component: () => import('../views/pub/UsrJoin04.vue')
  },
  {
    path: '/userTerms',
    name: 'userTerms',
    component: () => import('../views/user/userTerms.vue')
  },
  {
    path: '/userAuthentication',
    name: 'userAuthentication',
    component: () => import('../views/user/userAuthentication.vue')
  },
  {
    path: '/userRegForm',
    name: 'userRegForm',
    component: () => import('../views/user/userRegForm.vue')
  },
  {
    path: '/userJoinComplete',
    name: 'userJoinComplete',
    component: () => import('../views/user/userJoinComplete.vue')
  },
  {
    path: '/userLogin',
    name: 'userLogin',
    component: () => import('../views/login/userLogin.vue')
  },
  {
    path: '/emptyPage',
    name: 'emptyPage',
    component: () => import('../views/pub/EmptyPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
