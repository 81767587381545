<style>
    @import '@/assets/css/base.css';
    @import '@/assets/css/font.css';
    @import '@/assets/css/usr_common.css';
    @import '@/assets/css/usr.css';
</style>
<template>
  <LoadingView v-if="this.$store.state.isLoading" />
  <ul id="skip_navigation">
		<li><a href="#mainMenu"><h2>주메뉴 바로가기</h2></a></li>
		<li><a href="#container"><h2>본문 바로가기</h2></a></li>
	</ul>
  <HeaderView />
  <TopMenuView />
  <div id="container">
    <SubLnbView v-if="isSubLnbView" />
    <!-- 메인 페이지 용 -->
    <router-view  v-if="!isSubLnbView" />
    <!--서브 페이지 용-->
    <div class="inner" v-if="isSubLnbView">
      <PageTitleView />
      <router-view/>
    </div>
  </div>
  <FooterView />
  <div id="mask" v-if="this.$store.state.isMask"></div>
  <!--To TOP 버튼-->
  <div id="totop">
    <a href="#none" class="totop" title="페이지 상단 이동"><i class="micon">arrow_upward</i></a>
  </div> 
</template>
<script>

  // 로딩
  import LoadingView from '@/components/LoadingView.vue';

  // 해더 
  import HeaderView from '@/inc/HeaderView.vue';

  // 서브 LNB
  import SubLnbView from '@/inc/SubLnbView.vue';

  // 서브 페이지 타이블
  import PageTitleView from '@/inc/PageTitleView.vue';

  // 탑메뉴
  import TopMenuView from '@/inc/TopMenu.vue';

  // 푸터
  import FooterView from '@/inc/FooterView.vue';

  // 공통 함수
  import * as fnc from '@/assets/js/Common.js';

  // jQuery  
  import $ from 'jquery';

  export default {
    name: 'ScmsUsr',
    data: () => ({
        isSubLnbView:false
    }),
    methods:{
      getSiteInfo:function(){
        fnc.getHttpResponse('/api/common/getSiteInfo',"").then(res =>{                    
          const siteInfo = res.data.siteInfo;          
          if(siteInfo != undefined){
              $('html > head > title').text(siteInfo.siteTitle);              
              $("#ogTitle").attr("content",siteInfo.siteDesc);
              $("#ogUrl").attr("content",siteInfo.siteUrl);
              const ogImgUrl = siteInfo.siteUrl + "api/common/getFile/SITE";              
              $("#ogImage").attr("content",ogImgUrl);
              this.$store.commit("siteInfoSet",siteInfo);
          }else{              
              this.$store.commit("siteInfoSet",[]);
          }
        });
      },
      sessionCheck(){
        fnc.getHttpResponse('/api/login/sessionChk',this.form).then(res =>{
          const isLogin = res.data.IS_LOGIN;          
          if(isLogin == "Y"){
            const userInfo = res.data.userData;          
            this.$store.commit("userInfoSet",userInfo);
            this.$store.commit('loginStateSet',true);
            //this.getStorePages();
          }else{
            this.$store.commit('loginStateSet',false);
          }        
        });
      }
    },
    components: {
      HeaderView,      
      LoadingView,
      TopMenuView,
      FooterView,
      SubLnbView,
      PageTitleView
    },
    mounted(){
      this.getSiteInfo();
      this.sessionCheck();
    },
    computed:{
      isLogin(){
        return this.$store.state.isLogin;
      }
    },
    watch:{
      // 라우터 변경 체크
      $route(to,from){
        if(to.path !== from.path){      
          if(to.path == "/"){
            this.isSubLnbView = false;
          }else{
            this.isSubLnbView = true;
          }
        }
      },
      isLogin(){
        this.sessionCheck();
      }
    }
  }

</script>
