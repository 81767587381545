<template>
    <div class="noti">
        <!-- 아래 클릭할때 마다 바뀌어라 -->
        <ul class="notiTab">
            <li><a href="#none" class="on" title="선택됨" @click.prevent="clickNotiTab">공지사항</a></li>
            <li><a href="#none" @click.prevent="clickNotiTab">뉴스레터</a></li>
        </ul>
        <ul class="notiListW">
            <!-- 공지사항 탭 -->
            <li class="list on">
                <ul class="notiList">
                    <li><a href="#none"><p class="txt"><span class="label">공지</span>외국 박물관 한국실 지원 사업 참여 기간 모집 공고외국 박물관 한국실 지원 사업 참여 기간 모집 공고외국 박물관 한국실 지원 사업 참여 기간 모집 공고외국 박물관 한국실 지원 사업 참여 기간 모집 공고</p><p class="date">2023-00-00</p></a></li>
                    <li><a href="#none"><p class="txt"><span class="label">공지</span>분실물 정보 안내</p><p class="date">2023-00-00</p></a></li>
                    <li><a href="#none"><p class="txt">매달 마지막 수요일은 ‘문화가 있는 날’</p><p class="date">2023-00-00</p></a></li>
                    <li><a href="#none"><p class="txt">세계문화관 중국실 임시 휴무 알림 (5.23 - 5.24)</p><p class="date">2023-00-00</p></a></li>
                    <li><a href="#none"><p class="txt">외국 박물관 한국실 지원 사업 참여 기관 모집 공고</p><p class="date">2023-00-00</p></a></li>
                </ul>
            </li>
            <!-- 뉴스레터 탭 -->
            <li class="list">
                <ul class="notiList">
                    <li><a href="#none"><p class="txt">매달 마지막 수요일은 ‘문화가 있는 날’</p><p class="date">2023-00-00</p></a></li>
                    <li><a href="#none"><p class="txt">세계문화관 중국실 임시 휴무 알림 (5.23 - 5.24)</p><p class="date">2023-00-00</p></a></li><li><a href="#none"><p class="txt">세계문화관 중국실 임시 휴무 알림 (5.23 - 5.24)</p><p class="date">2023-00-00</p></a></li>
                    <li><a href="#none"><p class="txt">외국 박물관 한국실 지원 사업 참여 기관 모집 공고</p><p class="date">2023-00-00</p></a></li>
                    <li><a href="#none"><p class="txt">외국 박물관 한국실 지원 사업 참여 기관 모집 공고</p><p class="date">2023-00-00</p></a></li>
                </ul>
            </li>
        </ul>
        <a href="#none" class="more"><i class="micon">add</i>더보기</a>
    </div>
</template>
<script>

    import $ from 'jquery';

    export default {           
        methods:{
            clickNotiTab:function(e){
                const target = $(e.target);
                var Tab = $(".mainsec .notiW .noti .notiTab > li a").index(target);		
                if (!$(target).hasClass("on")){
                    $(".mainsec .notiW .noti .notiTab > li a").removeClass("on");
                    $(target).addClass("on");
                    $(".mainsec .notiW .noti .notiListW > li").removeClass("on");
                    $(".mainsec .notiW .noti .notiListW > li").eq(Tab).addClass("on");
                } 
            }
        }
    }
</script>