<template>
    <div id="top">
        <h1 class="mob logo"><a href="/" class="go_home" id="home"><img src="@/assets/images/common/logo2.svg" alt="SCMS 2.0"></a></h1>
        <ul class="myMenu">
            <li v-if="!isLogin"><router-link to="/userTerms" class="M my_join" @click.prevent="showLoading">회원가입</router-link></li>
            <li v-if="!isLogin"><router-link to="/userLogin" class="M my_login">로그인</router-link></li>
            <li v-if="isLogin"><a href="#none" class="M my_login" @click="logout">로그아웃</a></li>
            <li v-if="isLogin">
                <a href="#none" class="M my_menu"><i class="micon">account_circle</i><span class="name">{{userName}}</span> 님 <i class="micon arrow">arrow_drop_down</i></a>
                <div class="modal menuModal">
                    <p class="name">{{userName}}<span class="body2 sm">님</span></p>
                    <p class="caption">최종 로그인 : 2022-07-14 11:13:28</p>
                    <ul class="clr logmenu">
                        <li><a href="#none" class="mpage body2">마이페이지</a></li>
                        <li><a href="#none" class="logout body2" @click="logout">로그아웃</a></li>
                    </ul>
                </div>
            </li>
        </ul>
        <ul class="mob menu clr">
            <li><a href="#none" class="menu_search" title="통합검색"><i class="micon">search</i></a></li>
            <li><a href="#none" class="menu_all" title="전체메뉴" @click.prevent="allMenuOpen"><i class="micon">menu</i></a></li>
        </ul>
    </div>
</template>
<script>

    import $ from 'jquery';
    import * as fnc from '@/assets/js/Common.js';

    export default {  
        data: () =>({  
           
        }),    
        methods:{
            showLoading:function(){
                this.$store.commit('loadingStateSet', true);
                setTimeout(() =>{
                    this.$store.commit('loadingStateSet', false);
                },3000);
            },
            allMenuOpen:function(){
                console.log("OPEN");
                this.$store.commit('maskStateSet', true);
                setTimeout(() =>{
                    $("#allmenu").css({marginRight:'0', transitionDuration:'0.5s'});
                },50); 
            },
            logout:function(){
                fnc.getHttpResponse("/api/login/logout").then(res => {
                    const result = res.data.result;
                    if(result =="Y"){
                        alert("정상적으로 로그아웃되었습니다.");
                        this.$store.commit("userInfoSet", null)
                        this.$store.commit('loginStateSet',false);
                        this.$router.push('/');
                    }else{
                        alert("로그아웃중 오류가 발생하였습니다.");
                    }
                })
            }
        },
        computed:{
            userName(){                
                var userName = "";
                if(this.$store.state.userInfo != null){
                    userName = this.$store.state.userInfo.name; 
                }
                return userName;
            },
            isLogin(){
                return this.$store.state.isLogin
            }
        },
 
    }
</script>