<template>
    <div class="conTitle">
        <h2 class="title">{{pageInfo.menuNm}}</h2>
        <ul class="util">
            <li><i class="micon">home</i></li>
            <li>{{pageInfo.topMenuNm}}</li>
            <li v-if="pageInfo.upperMenuNm != null">{{pageInfo.upperMenuNm}}</li>
            <li>{{pageInfo.menuNm}}</li>
        </ul>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            };
        },computed:{
            pageInfo(){
                var pageInfo = this.$store.state.pageInfo;                
                return pageInfo;
            }
        }
    };

</script>