<template>
    <!-- GNB -->
    <div id="gnb">
        <div class="gnbShadow"></div>
        <h1 class="logo"><router-link to="/" class="go_home" id="home"  @focus="headerSubMenuHide"><img src="/api/common/getFile/LOGO" height="38" id="logoImg" v-bind:alt="siteInfo.siteDesc"></router-link></h1>        
        <ul class="gnb" @mouseover="headerSubMenuView" @mouseleave="headerSubMenuHide"  >            
            <li class="dep01" v-for="(item,seq) in menuList" :key="seq">
                <a href="#none" class="mainMenu" @focus="headerSubMenuView">{{item.menuNm}}</a>                
                <ul class="dep02" v-if="this.isSubView">
                    <li v-for="(item,seq) in item.subMenu" :key="seq">
                        <!-- 3단계 메뉴가 없을때 -->
                        <router-link :to="{path: item.menuUrl}" v-if="item.subMenu == null">{{item.menuNm}}</router-link>
                        <!-- 3단계 메뉴가 없을때 끝 -->
                        <!-- 3단계 메뉴가 있을때 -->
                        <button type="button"  v-if="item.subMenu != null" @click="headerLastLevelToggle">{{item.menuNm}} <i class="micon">add</i></button>
                        <ul class="dep03"  v-if="item.subMenu != null">
                            <li v-for="(item,seq) in item.subMenu" :key="seq"><router-link :to="{path: item.menuUrl}">{{item.menuNm}}</router-link></li>                            
                        </ul>
                        <!-- 3단계 메뉴가 있을때 끝 -->
                    </li>
                </ul>
            </li>
            <!-- 퍼블 샘플 -->
            <!--
            <li class="dep01">
                <a href="#none">게시판 샘플</a>
                <ul class="dep02" v-if="this.isSubView">                    
                    <li><router-link to="/noticeList">게시판 - 일반형</router-link></li> 
                    <li>
                        <button type="button" @click="headerLastLevelToggle">게시판 - 갤러리형 <i class="micon">add</i></button>
                        <ul class="dep03">
                            <li><router-link to="/boardBlog">블로그형</router-link></li>
                            <li><router-link to="/boardGallery">앨범형</router-link></li>
                            <li><router-link to="/boardBanner">배너형</router-link></li>                            
                        </ul>                    
                    </li>
                    <li><router-link to="/boardGallery">게시판 - 앨범형</router-link></li>
                    <li><router-link to="/boardBanner">게시판 - 배너형</router-link></li>
                                   
                    <li><router-link to="/boardGallery">게시판 - 앨범형</router-link></li>
                    <li><router-link to="/boardBanner">게시판 - 배너형</router-link></li>
                    <li><router-link to="/boardRefer">게시판 - 자료실</router-link></li>
                    <li><router-link to="/boardFaq">게시판 - FAQ</router-link></li>
                    <li><router-link to="/boardQna">게시판 - Q&A</router-link></li>
                </ul>
            </li>
            -->
            <li class="dep01">
                <a href="#none" class="on">커뮤니티</a>
                <ul class="dep02" v-if="this.isSubView">                    
                    <li><router-link to="/eventList">이벤트</router-link></li>
                    <li><router-link to="/surveyList">설문조사</router-link></li>    
                </ul>
            </li>
            <li class="dep01">
                <a href="#none">이용안내</a>
                <ul class="dep02" v-if="this.isSubView">
                    <li><router-link to="/policyInfo">개인정보처리방침</router-link></li>
                </ul>
            </li>
            <li class="dep01">
                <a href="#none">회원메뉴</a>
                <ul class="dep02" v-if="this.isSubView">
                    <li><router-link to="/usrJoin01">회원가입</router-link></li>
                    <li><router-link to="/usrLogin">로그인</router-link></li>
                    <li><a href="/contents/corrected.jsp">회원정보수정</a></li>
                </ul>
            </li>
        </ul>

        <ul class="menu clr">
            <li><a href="#none" class="menu_search" title="통합검색" @click.prevent="searchPopToggle" @focus="headerSubMenuHide"><i class="micon">search</i></a>
                <div class="searchPop">
                    <div class="area">
                        <fieldset>
                            <legend>통합검색</legend>
                            <label><input type="text"></label>
                            <button type="button" class="srhBtn" title="검색"><i class="micon">search</i></button>
                        </fieldset>
                        <button type="button" class="close" title="닫기" @click="searchPopClose"><i class="micon">close</i></button>
                    </div>
                </div>
            </li>
            <li><a href="#none" class="menu_all" title="전체메뉴" @click.prevent="allMenuOpen"><i class="micon">menu</i></a></li>
        </ul>
    </div>
    <!-- //GNB -->




    <!-- 전체메뉴 -->
    <div id="allmenu">
        <ul class="mGnb">
            <li class="dep01" v-for="(item,seq) in menuList" :key="seq"><a href="#none" v-bind:class="{on:seq == 0}" @click.prevent="allMenuClick">{{item.menuNm}}</a></li>            
        </ul>
        <!--모바일용 회원가입 메뉴 -->
        <ul class="mUtil">
            <li><a href="/contents/join01.jsp">회원가입</a></li>
            <li><a href="/contents/login.jsp">로그인</a></li>
            <li><a href="/contents/corrected.jsp">마이페이지</a></li>
        </ul>

        <div class="mSnb" >
            <ul class="dep02" v-bind:class="{on:seq == 0}" v-for="(item,seq) in menuList" :key="seq">
                <li class="menu" v-for="(item,seq) in item.subMenu" :key="seq">                    
                    <!-- 3단계 메뉴가 없을때 -->
                    <router-link :to="{path: item.menuUrl}" v-if="item.subMenu == null">{{item.menuNm}}</router-link>
                    <!-- 3단계 메뉴가 없을때 끝 -->
                    <!-- 3단계 메뉴가 있을때 -->
                    <button type="button"  v-if="item.subMenu != null" @click="headerLastLevelToggle">{{item.menuNm}} <i class="micon">add</i></button>
                    <ul class="dep03"  v-if="item.subMenu != null">
                        <li v-for="(item,seq) in item.subMenu" :key="seq"><router-link :to="{path: item.menuUrl}">{{item.menuNm}}</router-link></li>                            
                    </ul>
                    <!-- 3단계 메뉴가 있을때 끝 -->
                </li>
            </ul>            
        </div>
        <button type="button" class="close" title="닫기" @click="allMenuClose"><i class="micon">close</i></button>
    </div>

    <!-- //전체메뉴 -->
</template>
<script>
    // 공통 함수
    import * as fnc from '@/assets/js/Common.js';

    // jQuery
    import $ from 'jquery';

    export default {    
        data: () =>({
            isSubView:false,            
            menuList:[]
        }),
        methods:{
            getMenuData:function(){
                fnc.getHttpResponse('/api/common/getUsrMenuList','').then(res =>{
                    this.$store.commit("menuInfoSet",res.data.menuList);   
                    this.menuList = this.$store.state.menuInfo;                    
                });
            },
            getPageInfo:function(){
                const url = window.location.pathname;
                const formData = new FormData();                
                formData.append("pageUrl",url);
                fnc.getHttpResponse('/api/common/getPageInfo',formData).then(res =>{                    
                    const pageInfo = res.data.pageInfo;                    
                    if(pageInfo != undefined){
                        this.$store.commit("pageInfoSet",pageInfo);
                    }else{
                        this.$store.commit("pageInfoSet",[]);
                    }
                });
            },
            headerSubMenuView:function(){
                this.isSubView = true;
            },
            headerSubMenuHide:function(){
                this.isSubView = false;
            },
            headerLastLevelToggle:function(e){                                                
                const targetLi = $(e.target);                
                if(!$(targetLi).find(".micon").hasClass('on')){
                    $(targetLi).find(".micon").addClass('on');
                    $(targetLi).find(".micon").html('remove');
                    $(targetLi).parent('li').find(".dep03").stop(true).slideDown();
                } else {
                    $(targetLi).find(".micon").removeClass('on');
                    $(targetLi).find(".micon").html('add');
                    $(targetLi).parent('li').find(".dep03").stop(true).slideUp();
                }                
            },
            searchPopToggle:function(){
                $("#gnb .menu .searchPop").stop(true).slideToggle();
            },
            searchPopClose:function(){
                $("#gnb .menu .searchPop").slideUp();
            },
            allMenuOpen:function(){                
                this.$store.commit('maskStateSet', true);
                setTimeout(() =>{
                    $("#allmenu").css({marginRight:'0', transitionDuration:'0.5s'});
                },50); 
            },
            allMenuClose:function(){
                $("#allmenu").css({marginRight:'-430px', transitionDuration:'0.5s'});
                setTimeout(() =>{
                    this.$store.commit('maskStateSet', false);
                },300);
            },
            allMenuClick:function(e){
                const targetLi = $(e.target);  
                $("#allmenu .mGnb > li a").removeClass('on');
                $(targetLi).addClass('on');

                var mSnb = $("#allmenu .mGnb > li a").index(targetLi);
                $("#allmenu .mSnb .dep02").hide();
                $("#allmenu .mSnb .dep02").eq(mSnb).show();
            }
        },
        mounted(){

            // 스크롤 Top 설정
            var btn = $('#totop .totop');

            $(window).scroll(function() {
                if ($(window).scrollTop() > 300) {
                btn.addClass('show');
                } else {
                btn.removeClass('show');
                }
            });

            // 스크롤 Top 클릭 이벤트
            btn.on('click', function(e) {
                e.preventDefault();
                $('html, body').animate({scrollTop:0}, '300');
            });

            this.getMenuData();            
        },watch:{
            // 라우터 변경 체크
            $route(){               
                // 상단메뉴 닫기
                this.isSubView = false;
                // 1단 LNB 닫기
                $(".depth1").removeClass('on');
                $(".depth1").parent('li').find(".depList").stop(true).slideUp();
                // 2단 LNB 닫기
                $(".depth2").removeClass('on');
                $(".depth2").parent('li').find(".depList").stop(true).slideUp();
                // 3단 LNB 닫기
                $(".depth3").removeClass('on');
                $(".depth3").parent('li').find(".depList").stop(true).slideUp();
                // 상단 옆에 메뉴 닫기
                this.allMenuClose();
                this.getPageInfo();
            }
        },computed:{
            siteInfo(){
                var siteInfo = this.$store.state.siteInfo;
                return siteInfo;
            }
        }
    }


    
</script>
