<template>    
  <swiper
  :slides-per-view="1"
  :space-between="50"
  :loop="true"
  :autoplay="{
    delay: 3000,
    disableOnInteraction: false,
  }"
  :modules="modules"
  @swiper="onSwiper"
  @slideChange="onSlideChange"
>
  <swiper-slide><li><a href="#none"><img src="@/assets/images/common/sampleV.svg" alt=""></a></li></swiper-slide>
  <swiper-slide><li><a href="#none"><img src="@/assets/images/common/sampleV.svg" alt=""></a></li></swiper-slide>    
</swiper>      
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

import jQuery from 'jquery';

// Import Swiper styles
import 'swiper/css';

import { Autoplay} from 'swiper';

export default {
components: {
  Swiper,
  SwiperSlide,
},
setup() {
  const onSwiper = () => {
    //console.log(swiper);
  };
  const onSlideChange = () => {
    //console.log('slide change');
  };
  return {
    modules: [Autoplay],
    onSwiper,
    onSlideChange,
  };
},methods:{
    handleResize:function(){
      
    }
},mounted(){
  // 초기 사이즈 셋팅
  setTimeout(function(){
    const bodyWidth = jQuery(document).width() - 50;        
    jQuery(".swiper-wrapper").width(bodyWidth);
  },50);      
  // 리사이즈 이벤트
  window.addEventListener("resize", function() {
    const bodyWidth = jQuery(document).width() - 50;         
    jQuery(".swiper-wrapper").width(bodyWidth);
  });
}
};
</script>
<style>
.swiper-wrapper{width:300px;}
</style>