import { createStore } from "vuex";

const store = createStore({
    state(){
        return{
            isLogin : true,
            isLoading : false,
            isMask : false,
            menuInfo:{},
            userInfo:{},
            pageInfo:{},
            siteInfo:{}
        }
    },
    mutations:{
        loadingStateSet(state,loadingChk){
            state.isLoading = loadingChk
        },
        maskStateSet(state,maskChk){
            state.isMask = maskChk
        },
        menuInfoSet(state,menuInfo){
            state.menuInfo = menuInfo
        },
        loginStateSet(state,loginChk){
            state.isLogin = loginChk
        },
        userInfoSet(state,userInfo){
            state.userInfo = userInfo
        },        
        pageInfoSet(state,pageInfo){
            state.pageInfo = pageInfo
        },
        siteInfoSet(state,siteInfo){
            state.siteInfo = siteInfo
        }
    },actions:{
        
    }
})

export default store;