<template>
    <!--카드형 선택 게시판(카테고리 없을때)-->
    <section class="mainsec" style="background:#FCFBFD;">
        <div class="inner">
            <h2 class="title">카드형 선택 게시판</h2>
            <ul class="card">
                <li><a href="#none">
                    <div class="txtW">
                        <p class="label">NEW</p>
                        <p class="tit">코리아 오픈대회로 인한 실내레슨</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                    <div class="etcW">
                        <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                        <p class="view"><i class="micon">visibility</i> 123</p>
                    </div>
                </a></li>
                <li><a href="#none">
                    <div class="txtW">
                        <p class="label">UPDATE</p>
                        <p class="tit">코리아 오픈대회로 인한 실내레슨 불가에 대한 문제점</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                    <div class="etcW">
                        <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                        <p class="view"><i class="micon">visibility</i> 123</p>
                    </div>
                </a></li>
                <li><a href="#none">
                    <div class="txtW">
                        <p class="label">UPDATE</p>
                        <p class="tit">코리아 오픈대회로 인한 실내레슨 불가에 대한 문제점 시정 요청</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                    <div class="etcW">
                        <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                        <p class="view"><i class="micon">visibility</i> 123</p>
                    </div>
                </a></li>
                <li><a href="#none">
                    <div class="txtW">
                        <p class="label">2023 사업</p>
                        <p class="tit">코리아 오픈대회로 인한 실내레슨</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                    <div class="etcW">
                        <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                        <p class="view"><i class="micon">visibility</i> 123</p>
                    </div>
                </a></li>
                <li><a href="#none">
                    <div class="txtW">
                        <p class="label">NEW</p>
                        <p class="tit">코리아 오픈대회로 인한 실내레슨 불가에 대한 문제점</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                    <div class="etcW">
                        <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                        <p class="view"><i class="micon">visibility</i> 123</p>
                    </div>
                </a></li>
                <li><a href="#none">
                    <div class="txtW">
                        <p class="label">NEW</p>
                        <p class="tit">코리아 오픈대회로 인한 실내레슨</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                    <div class="etcW">
                        <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                        <p class="view"><i class="micon">visibility</i> 123</p>
                    </div>
                </a></li>
                <li><a href="#none">
                    <div class="txtW">
                        <p class="label">2023 사업</p>
                        <p class="tit">코리아 오픈대회로 인한 실내레슨 불가에 대한 문제점코리아 오픈대회로 인한 실내레슨 불가에 대한 문제점</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                    <div class="etcW">
                        <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                        <p class="view"><i class="micon">visibility</i> 123</p>
                    </div>
                </a></li>
                <li><a href="#none">
                    <div class="txtW">
                        <p class="label">UPDATE</p>
                        <p class="tit">코리아 오픈대회로 인한 실내레슨 불가에 대한 문제점</p>
                        <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                    </div>
                    <div class="etcW">
                        <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                        <p class="view"><i class="micon">visibility</i> 123</p>
                    </div>
                </a></li>
            </ul>
            <a href="#none" class="more btn default"><i class="micon">add</i>더보기</a>
        </div>
    </section>
    <!--//카드형 선택 게시판-->
    <!--카드형 선택 게시판 (카테고리 있을때) -->
    <section class="mainsec" style="background:#FCFBFD;">
        <div class="inner">
            <h2 class="title">카드형 선택 게시판</h2>
            <div class="tabCard">
                <ul class="cardTab">
                    <li><a href="#none" class="btn on" title="선택됨" @click.prevent="selectCardCategory">전체보기</a></li>
                    <li><a href="#none" class="btn" @click.prevent="selectCardCategory">NEW</a></li>
                    <li><a href="#none" class="btn" @click.prevent="selectCardCategory">UPDATE</a></li>
                    <li><a href="#none" class="btn" @click.prevent="selectCardCategory">2023사업</a></li>
                    <li><a href="#none" class="btn" @click.prevent="selectCardCategory">FREE</a></li>
                </ul>
                <ul class="card">
                    <li><a href="#none">
                        <div class="txtW">
                            <p class="label">NEW</p>
                            <p class="tit">코리아 오픈대회로 인한 실내레슨</p>
                            <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                        </div>
                        <div class="etcW">
                            <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                            <p class="view"><i class="micon">visibility</i> 123</p>
                        </div>
                    </a></li>
                    <li><a href="#none">
                        <div class="txtW">
                            <p class="label">UPDATE</p>
                            <p class="tit">코리아 오픈대회로 인한 실내레슨 불가에 대한 문제점</p>
                            <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                        </div>
                        <div class="etcW">
                            <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                            <p class="view"><i class="micon">visibility</i> 123</p>
                        </div>
                    </a></li>
                    <li><a href="#none">
                        <div class="txtW">
                            <p class="label">UPDATE</p>
                            <p class="tit">코리아 오픈대회로 인한 실내레슨 불가에 대한 문제점 시정 요청</p>
                            <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                        </div>
                        <div class="etcW">
                            <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                            <p class="view"><i class="micon">visibility</i> 123</p>
                        </div>
                    </a></li>
                    <li><a href="#none">
                        <div class="txtW">
                            <p class="label">2023 사업</p>
                            <p class="tit">코리아 오픈대회로 인한 실내레슨</p>
                            <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                        </div>
                        <div class="etcW">
                            <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                            <p class="view"><i class="micon">visibility</i> 123</p>
                        </div>
                    </a></li>
                    <li><a href="#none">
                        <div class="txtW">
                            <p class="label">NEW</p>
                            <p class="tit">코리아 오픈대회로 인한 실내레슨 불가에 대한 문제점</p>
                            <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                        </div>
                        <div class="etcW">
                            <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                            <p class="view"><i class="micon">visibility</i> 123</p>
                        </div>
                    </a></li>
                    <li><a href="#none">
                        <div class="txtW">
                            <p class="label">NEW</p>
                            <p class="tit">코리아 오픈대회로 인한 실내레슨</p>
                            <p class="txt">경상북도 포항시 남구 구룡포읍 호미로 426번길 6경상북도 포항시 남구 구룡포읍 호미로 426번길 6</p>
                        </div>
                        <div class="etcW">
                            <p class="date"><i class="micon">access_time</i> 2023-00-00</p>
                            <p class="view"><i class="micon">visibility</i> 123</p>
                        </div>
                    </a></li>
                </ul>
            </div>
            <a href="#none" class="more btn default"><i class="micon">add</i>더보기</a>
        </div>
    </section>
    <!--//카드형 선택 게시판-->
</template>
<script>

    import $ from 'jquery';

    export default {           
        methods:{
            selectCardCategory:function(e){
                const target = $(e.target);
                if (!$(target).hasClass('on')) {
                    $(".mainsec .tabCard .cardTab > li a").removeClass('on');
                    $(".mainsec .tabCard .cardTab > li a").attr('title', '');
                    $(target).addClass('on');
                    $(target).attr('title', '선택됨');
                }
            }
        }
    }
</script>
