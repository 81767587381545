<template>
    <div id="loader">
		<div class="spinner-box">
			<div class="configure-border-1">  
				<div class="configure-core"></div>
			</div>
			<div class="configure-border-2">
				<div class="configure-core"></div>
			</div>
			<p class="txt">LOADING</p>
		</div>
	</div>
</template>