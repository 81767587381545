<template>
    <div id="footer">
        <div class="inner clr">
            <div class="fotTop">
                <ul class="agreeTxt">
                    <li><a href="#none">이용자약관</a></li>
                    <li><a href="#none" class="em">개인정보취급방침</a></li>
                </ul>
                <ul class="siteList">
                    <li>
                        <a href="#none" @click.prevent="siteToggle">패밀리사이트<i class="micon">arrow_drop_up</i></a>
                        <ul class="list">
                            <li><a href="#none">패밀리사이트 01</a></li>
                            <li><a href="#none">패밀리사이트 02</a></li>
                            <li><a href="#none">패밀리사이트 03</a></li>
                            <li><a href="#none">패밀리사이트 04</a></li>
                            <li><a href="#none">패밀리사이트 05</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#none" @click.prevent="siteToggle">관련사이트<i class="micon">arrow_drop_up</i></a>
                        <ul class="list">
                            <li><a href="#none">관련사이트 01</a></li>
                            <li><a href="#none">관련사이트 02</a></li>
                            <li><a href="#none">관련사이트 03</a></li>
                            <li><a href="#none">관련사이트 04</a></li>
                            <li><a href="#none">관련사이트 05</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="fotBot">
                <a href="#none" class="fotLogo"><img src="/api/common/getFile/LOGO" height="38" id="logoImg" v-bind:alt="siteInfo.siteDesc"></a>
                <div class="fotTxt">
                    <ul>
                        <li>{{siteInfo.addr}}</li>
                        <li>대표전화 : {{siteInfo.siteTel}}</li>
                    </ul>
                    <p class="copyright">COPYRIGHT (C) 2023 {{siteInfo.compName}}. ALL RIGHTS RESERVED.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery';

    export default {    
        methods:{
            siteToggle:function(e){
                const target = $(e.target);       
                if (!$(target).hasClass('on')) {
                    $(target).addClass('on');
                    $(target).parent('li').find(".list").stop(true).slideDown();
                } else {
                    $(target).removeClass('on');
                    $(target).parent('li').find(".list").stop(true).slideUp();
                }
            }
        },computed:{
            siteInfo(){
                var siteInfo = this.$store.state.siteInfo;
                return siteInfo;
            }
        }
    }
</script>