<template>
    <!--메인비주얼-->
	<section id="visual">
		<ul class="bg">
			<li><img src="@/assets/images/main/slide_img.jpg" alt=""></li>
		</ul>
		<div class="inner">
			<div class="txtarea">
				<p class="title">IT SERVICE PROVIDER<br/><span class="em">SILLASYSTEM</span></p>
				<p class="txt">고객을 위한 <span class="em">가치창조</span>, 고객과 함께하는 <span class="em">기술혁명</span><br/>(주)신라시스템이 만들어 가겠습니다.</p>
			</div>
		</div>
	</section>
    <!--//메인비주얼-->
</template>