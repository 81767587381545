<template>    
    <VisualView />
    <PhotoGalleryView />
    <CardGalleryView />    
    <!--새소식 알림-->
    <section class="mainsec">
        <div class="inner">
            <h2 class="title">새소식 알림</h2>
            <div class="notiW">
                <NoticeView />
                <MainSlide />
            </div>
        </div>
    </section>
    <!--//새소식 알림-->
</template>
<script>
    // 메인 비주얼
    import VisualView from '@/views/home/component/VisualView.vue';

    // 포토형 갤러리
    import PhotoGalleryView from '@/views/home/component/PhotoGalleryView.vue';

    // 카드형 갤러리
    import CardGalleryView from  '@/views/home/component/CardGalleryView.vue';

    // 공지사항 게시판
    import NoticeView from '@/views/home/component/NoticeView.vue';

    // 메인 슬라이드
    import MainSlide from '@/views/home/component/SlideView.vue';
    
  
    export default {    
        components: {
            VisualView,
            PhotoGalleryView,
            CardGalleryView,
            NoticeView,
            MainSlide
        }
    }
</script>